import axios from "axios";

export default {
  getLog: (params) => {
    return axios.get("/a1/presell/goods/log", { params: params });
  },

  getPresellEditGoodsLog: (params) => {
    return axios.get("/a1/presell/goods/editlog", { params: params });
  },

  moveTestImg: (params) => {
    return axios.put("/a1/presellConfig/moveTestImg", params);
  },
  deleteTestImg: (params) => {
    return axios.put("/a1/presellConfig/deleteTestImg", params);
  },
  deleteImg: (params) => {
    return axios.put("/a1/presellConfig/deleteImg", params);
  },
  getPresellActGoods: (act_id, params) => {
    return axios.get("/a1/presellActivites/actGoodslist/" + act_id, { params: params });
  },
  batchGoodsDel: (params) => {
    return axios.delete("/a1/presellActivites/batchDelGoods", params);
  },
  // 获取预售活动列表
  getPresellActList: (params) => {
    return axios.get("/a1/presellActivites/list", { params: params });
  },
  // 获取预售活动列表
  getAllActList: (params) => {
    return axios.get("/a1/presellActivites/allList", { params: params });
  },
  getPresellType: () => {
    return axios.get("/a1/presell/preType");
  },
  // 获取预售活动用于下拉选择
  getPresellActSelect: () => {
    return axios.get("/a1/presellActivites/selectList");
  },
  // 预售订单添加商品
  upOrderGoodsSku(putData) {
    return axios.post(`/a1/presell/addPresellSku`, putData);
  },
  // 编辑添加商品的单价
  editOrderGoods(putData) {
    return axios.put(`/a1/presell/editOrderPresell`, putData);
  },

};
